import React from "react"
import Layout from "../components/layout/layout"

import SearchModule  from "../components/search2/searchModule"


const Search = ( ) => {


  return (
    <Layout  >
     <SearchModule showManufacturer={true} />
    </Layout>
  )
}

export default Search
